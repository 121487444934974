const url = window.location.origin;

export const getEnv = () => {
  switch (true) {
    case url.includes('one.cube.toyota.com'):
      return 'prod';
    case url.includes('one.cubeuat.toyota.com'):
      return 'uat';
    case url.includes('one.cubesit.toyota.com'):
      return 'sit';
    case url.includes('one.cubeprf.toyota.com'):
      return 'prf';
    case url.includes('one.cubeqa.toyota.com'):
      return 'qa';
    case url.includes('one.cubedev.toyota.com'):
    case url.includes('localhost'):
    default:
      return 'dev';
  }
};

export const getBaseURL = () =>
  ({
    dev: 'one.cubedev.toyota.com',
    qa: 'one.cubeqa.toyota.com',
    uat: 'one.cubeuat.toyota.com',
    sit: 'one.cubesit.toyota.com',
    prf: 'one.cubeprf.toyota.com',
    prod: 'one.cube.toyota.com',
  }[getEnv()]);
