import React from 'react';
import PropTypes from 'prop-types';
import { compact, find, join, map, split } from 'lodash';
import styled from '@emotion/styled';
import {
  AccessTimeFilledOutlined as AccessTimeFilledOutlinedIcon,
  BackHand as BackHandIcon,
  CelebrationOutlined as CelebrationIcon,
  DirectionsBoatOutlined as DirectionsBoatOutlinedIcon,
  DirectionsRailwayOutlined as DirectionsRailwayIcon,
  DoNotDisturbAltOutlined as DoNotDisturbAltOutlinedIcon,
  FactCheckOutlined as FactCheckIcon,
  LocalShippingOutlined as LocalShippingIcon,
  Pending as PendingIcon,
  PrecisionManufacturingOutlined as PrecisionManufacturingIcon,
  WarehouseOutlined as WarehouseIcon,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { Stack, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import NotRemovedIcon from 'icons/NotRemovedIcon/NotRemovedIcon';
import RemoveFromTrainingIcon from 'icons/RemoveFromTrainingIcon/RemoveFromTrainingIcon';
import SuppressEtaIcon from 'icons/SuppressEtaIcon/SuppressEtaIcon';
import StatusChip from 'components/StatusChip/StatusChip';
import ValueWithCopy from 'components/ValueWithCopy/ValueWithCopy';
import VehicleImageThumbnail from 'components/VehicleImageThumbnail/VehicleImageThumbnail';
import TooltipWrapper from 'components/TooltipWrapper/TooltipWrapper';
import theme from '../theme';
import { general } from './';
import { countGetTableIcon, getStatusChipColor, tableStatusMap } from './Arrival/statusUtils';
import { Chip } from 'cprime-foundational-components';
import { getEnv } from './environment';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const ContentWrapper = styled.div`
  width: fit-content;
`;

const WithTooltip = ({ value, tooltipText, placement = 'top' }) => (
  <TooltipWrapper tooltipText={tooltipText} placement={placement}>
    <ContentWrapper>{value}</ContentWrapper>
  </TooltipWrapper>
);

WithTooltip.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltipText: PropTypes.string,
  placement: PropTypes.string,
};

export const getJourneyStepColor = ({ isActive, isComplete, swEtaStatus }) => {
  if (swEtaStatus?.toLowerCase() === 'eta unavailable') {
    return theme.palette.common.black;
  }
  if (isComplete) {
    return theme.palette.common.black;
  }
  return isActive ? getStatusChipColor(swEtaStatus?.toLowerCase()) : theme.palette.secondary[30];
};

// Format date

export const formatDate = (timestamp, format) => {
  return timestamp ? dayjs.utc(timestamp).format(format) : null;
};

// Journey event icon generation

export const generateJourneyEventIcon = ({ eventName, color, position, bottom }) => {
  const styles = { color, position, bottom };
  switch (eventName) {
    case 'Prod. Confirm':
      return <FactCheckIcon sx={styles} />;
    case 'Build':
      return <PrecisionManufacturingIcon sx={styles} />;
    case 'Processing':
    case 'VPC Arrival':
    case 'VPC Ready':
      return <WarehouseIcon sx={styles} />;
    case 'Rail Depart':
    case 'Rail Arrive':
      return <DirectionsRailwayIcon sx={styles} />;
    case 'On Truck for Delivery':
    case 'Truck Arrive':
    case 'Truck Depart':
      return <LocalShippingIcon sx={styles} />;
    case 'Arrival at Dealership':
      return <CelebrationIcon sx={styles} />;
    case 'Vessel Depart':
    case 'Vessel Arrive':
      return <DirectionsBoatOutlinedIcon sx={styles} />;
    default:
      return <DoNotDisturbAltOutlinedIcon sx={styles} />;
  }
};

export const generateStatusIcon = (params) => {
  if (params.value.toLowerCase() === 'n/a') {
    return null;
  }
  const key = tableStatusMap[params.value.toLowerCase()];
  return countGetTableIcon[key]({ fontSize: '1rem' });
};

export const generateStatusChip = (params) => {
  if (params.row.swEtaStatus.toLowerCase() === 'n/a') {
    return null;
  }

  const isEtaUnavailable = params.row.swEtaStatus === 'ETA Unavailable';
  const color = getStatusChipColor(params.row.swEtaStatus);

  return (
    <Chip
      color={theme.palette.common.white}
      backgroundColor={isEtaUnavailable ? theme.palette.secondary[50] : color}
      label={params.value}
      variant="contained"
      sx={{
        '& .MuiTypography-root': {
          textTransform: 'none',
          fontWeight: 400,
        },
        '&, *': {
          cursor: 'pointer',
        },
      }}
    />
  );
};

const ADMIN_ACTION_MAP = {
  swSuppressionStatus: {
    date: 'swSuppressionLastUpdatedFmt',
    user: 'swSuppressionPreferredName',
    reason: 'swSuppressionComment',
  },
  swExclusionStatus: {
    date: 'swExclusionLastUpdatedFmt',
    user: 'swExclusionPreferredName',
    reason: 'swExclusionComment',
  },
};

const _getDetailText = (header, value) => (
  <Typography component="span" variant="label2">
    {
      <>
        <strong>{header}: </strong> {value}
      </>
    }
  </Typography>
);

export const withCopyIcon = ({ value, column, color }) => (
  <ValueWithCopy value={value} valueAlign={column.valueAlign}>
    <Typography component="span" variant="body4" color={color} noWrap>
      {value}
    </Typography>
  </ValueWithCopy>
);

export const withVehicleImage = (params) => (
  <VehicleImageThumbnail modelYear={params.row.modelYear} urn={params.row.urn} />
);

export const withPreProductionVin = ({ value, row, column }) => {
  const isPreProductionVin = row.preProductionVIN === value || row.preProductionVIN === row.vin;
  const color = isPreProductionVin ? 'secondary.30' : 'primary.main';

  return withCopyIcon({ value, column, color });
};

export const withHoldIcon = ({ value }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <StatusChip
      color={theme.palette.common.white}
      backgroundColor={theme.palette.warning.main}
      icon={<BackHandIcon color="common.white" />}
    />
    <Typography component="span" variant="body4" noWrap>
      {value}
    </Typography>
  </Stack>
);

const withIcon = ({ value, column, row, icon, color, backgroundColor }) => {
  const details = ADMIN_ACTION_MAP[column?.field];

  const tooltipText = (row[details.date] || row[details.user] || row[details.reason]) && (
    <Stack>
      {row[details.date] && _getDetailText('Date', row[details.date])}
      {row[details.user] && _getDetailText('User', row[details.user])}
      {row[details.reason] && _getDetailText('Reason', row[details.reason])}
    </Stack>
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%">
      <StatusChip color={color} backgroundColor={backgroundColor} icon={icon} tooltipText={tooltipText} />
      <Typography component="span" variant="body4" noWrap>
        {value}
      </Typography>
    </Stack>
  );
};

export const withSuppressionIcon = (props) => {
  const iconProps = {
    'Suppression Lifted': {
      icon: <AccessTimeFilledOutlinedIcon />,
      color: theme.palette.success[10],
      backgroundColor: theme.palette.success[100],
    },
    Pending: { icon: <PendingIcon />, color: theme.palette.warning[10], backgroundColor: theme.palette.warning[100] },
    Suppressed: {
      icon: <SuppressEtaIcon fill={theme.palette.data.onErrorContainer} />,
      backgroundColor: theme.palette.data.errorContainer,
    },
  };

  const { icon, color, backgroundColor } = iconProps[props.value] || {};

  if (!icon) {
    return null;
  }

  return withIcon({ ...props, icon, color, backgroundColor });
};

export const withTrainingSetIcon = (props) => {
  const iconProps = {
    'Added Back': {
      icon: <NotRemovedIcon fill={theme.palette.success[10]} />,
      color: theme.palette.success[10],
      backgroundColor: theme.palette.success[100],
    },
    Removed: {
      icon: <RemoveFromTrainingIcon fill={theme.palette.data.onErrorContainer} />,
      backgroundColor: theme.palette.data.errorContainer,
    },
  };

  const { icon, color, backgroundColor } = iconProps[props.value] || {};

  if (!icon) {
    return null;
  }

  return withIcon({ ...props, icon, color, backgroundColor });
};

export const withFleetIndicator = ({ value }) =>
  general.getTableChip(value, theme.palette.info[10], theme.palette.info[100]);

export const withPhaseChip = (params) => (
  <StatusChip
    color={theme.palette.info[10]}
    backgroundColor={theme.palette.info[100]}
    label={params.value.toUpperCase()}
    tooltipText={params.row.lifecycleSubstatusDescription}
  />
);

export const withTooltipAccessoriesDescription = ({ value, row }) => {
  const accessories = split(value, ' ');
  const tooltipText = join(
    compact(
      map(accessories, (code) => {
        const accessory = find(row.accessoriesDescription, { code });
        if (accessory) {
          return `${accessory.code} - ${accessory.description}`;
        }
        return null;
      }),
    ),
    '\n',
  );

  return React.createElement(WithTooltip, { value, tooltipText, placement: 'bottom' });
};

export const generateBoolFleetValue = (params) => (params.value ? 'Fleet' : 'Non-fleet');
export const generateBoolOnHoldValue = (params) => (params.value ? 'On Hold' : 'Not on Hold');
export const generateBoolOnQcHoldValue = (params) => (params.value ? 'On QC Hold' : 'Not on QC Hold');
export const generateBoolOnDamageHoldValue = (params) => (params.value ? 'On Damage Hold' : 'Not on Damage Hold');

export const numAddLeadingZeros = (params) => {
  if (!params?.value) {
    return 'N/A';
  }
  params.value = params.value.toString();
  return params.value.padStart(5, '0');
};

export const localize = (params) => {
  if (!params.value) {
    return 'N/A';
  }

  return params.value.toLocaleString();
};

export const localizedPercentage = (params) => {
  if (!params.value) {
    return 'N/A';
  }

  return params.value.toFixed(2).toLocaleString() + ' %';
};

export const formatRangeValues = (min, max, operator) => {
  switch (operator) {
    case 'between':
      return `>=${min} & <=${max}`;
    case 'ebetween':
      return `>${min} & <${max}`;
    case 'lbetween':
      return `>=${min} & <${max}`;
    case 'rbetween':
      return `>${min} & <=${max}`;
    default:
      return;
  }
};

const getFilterValue = (conditionKey, value) => {
  let result = value;
  const boolValue = value === 'true';

  switch (conditionKey) {
    case 'holdIndicator':
      result = generateBoolOnHoldValue({ value: boolValue });
      break;
    case 'damageHoldIndicator':
      result = generateBoolOnDamageHoldValue({ value: boolValue });
      break;
    case 'portModIndicator':
      result = generateBoolOnQcHoldValue({ value: boolValue });
      break;
    case 'fleetIndicator':
      result = generateBoolFleetValue({ value: boolValue });
      break;
    default:
      break;
  }
  return result;
};

export const isGroupField = (conditionKey, obj) =>
  ['and', 'or'].includes(conditionKey) && Array.isArray(obj[conditionKey]);

export const getGroupName = ({ data, skipGroupPrefix }) => {
  let result = skipGroupPrefix ? '' : 'GROUP: ';
  data.forEach((item, index) => {
    if (index === 3) {
      const hiddenItemCount = data.length - 3;
      result = result.concat(` + ${hiddenItemCount}`);
      return;
    } else if (index > 3) {
      return;
    }

    const condKey = Object.keys(item)[0];
    const groupField = isGroupField(condKey, item);
    let groupNames;
    if (groupField) {
      groupNames = getGroupName({ data: item[condKey], skipGroupPrefix: true });
    }
    const opKey = Object.keys(item[condKey])[0];
    result = result.concat(
      index === 0 ? '' : '/',
      groupField ? groupNames : getFilterValue(condKey, item[condKey][opKey]),
    );
  });
  return result;
};

export const formatQuery = (query) => {
  const env = getEnv();

  if (['uat', 'prod'].includes(env)) {
    return gql(query?.loc?.source?.body?.replace('awsRequestId\n', ''));
  }
  return query;
};

export const parseDateFormat = ({ dateValue, type }) => {
  if (type && type.toLowerCase() === 'date' && dateValue !== '' && dateValue !== null) {
    const baseFormats = [
      'MM/DD/YYYY',
      'MM/D/YYYY',
      'M/DD/YYYY',
      'M/D/YYYY',
      'D/M/YYYY',
      'D/MM/YYYY',
      'DD/M/YYYY',
      'DD/MM/YYYY',
      'YYYY/MM/DD',
    ];

    const separators = ['.', '-', ' '];
    const results = [];

    baseFormats.forEach((format) => {
      separators.forEach((sep) => {
        const replaced = format.replace(/\//g, sep);
        results.push(replaced);
      });
    });

    for (const format of [...baseFormats, ...results]) {
      const parsedDate = dayjs(dateValue, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.format('MM/DD/YYYY');
      }
    }
  }
  return dateValue;
};
