const { gql } = require('@apollo/client');

export const VEHICLE_IMAGES_THUMBNAIL = gql`
  query VehicleImagesThumbnail($urn: String!, $modelYear: Int!) {
    VehicleImagesThumbnail(urn: $urn, modelYear: $modelYear) {
      awsRequestId
      url
      alias
    }
  }
`;
